/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { PrismicImageType } from 'graphql-types'
import { H5, BodyLarge } from 'src/components/atoms/text'
import ComponentWithGifCursor from 'src/components/molecules/ComponentWithGifCursor'

type Value = {
  cursor_image: PrismicImageType
  text: { html: string }
}

type Props = {
  title: string
  values: Value[]
}

const EditorialValues: FC<Props> = ({ title, values }) => {
  return (
    <div sx={{ textAlign: 'center', mb: 'l2' }}>
      <H5 sx={{ mb: 'l', zIndex: 2000, position: 'relative' }}>
        {replaceHtml(title || '', 'p', BodyLarge)}
      </H5>
      <div>
        {values.map((obj, key) => {
          const {
            text,
            cursor_image: { dimensions, fluid },
          } = obj
          const { height = 0, width = 0 } = dimensions || {}
          const { src = '' } = fluid || {}

          return (
            <div key={key} sx={{ mb: 'm2' }}>
              <ComponentWithGifCursor
                cursorImage={{ height, width, src }}
                component={
                  <BodyLarge sx={{ zIndex: 2000, position: 'relative' }}>
                    {replaceHtml(text.html || '', 'p', BodyLarge)}
                  </BodyLarge>
                }
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EditorialValues
