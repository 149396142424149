import { useState, useEffect } from 'react'

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({
    mouseX: 0,
    mouseY: 0,
  })

  const updateMousePosition = (ev: any) => {
    setMousePosition({ mouseX: ev.clientX, mouseY: ev.clientY })
  }

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition)

    return () => window.removeEventListener('mousemove', updateMousePosition)
  }, [])

  return mousePosition as { mouseX: number; mouseY: number }
}

export default useMousePosition
