/** @jsxImportSource theme-ui */
import { FC, useState, useMemo } from 'react'
import { useResponsiveValue } from '@theme-ui/match-media'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { isMobile } from 'react-device-detect'

import useMousePosition from 'src/utils/useMousePosition'

const getCursorXY = (width: number, height: number, size: number) => {
  const calcHeight = size * (height / width)

  return {
    x: size / 2,
    y: calcHeight / 2,
  }
}

type ComponentWithGifCursorProps = {
  component: any
  cursorImage?: {
    src: string
    width: number
    height: number
  }
}

const ComponentWithGifCursorDesktop: FC<ComponentWithGifCursorProps> = ({
  component,
  cursorImage,
}) => {
  const { mouseX, mouseY } = useMousePosition()
  const [isHovered, setHovered] = useState(false)

  const onMouseEnter = () => setHovered(true)
  const onMouseLeave = () => setHovered(false)

  const size = useResponsiveValue(ml(168, 264))

  const { x, y } = useMemo(() => {
    const { x, y } = getCursorXY(
      cursorImage?.width || 264,
      cursorImage?.height || 264,
      size
    )

    return { x, y }
  }, [cursorImage?.width, cursorImage?.height, size])

  return (
    <div>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{ cursor: cursorImage?.src ? 'none' : 'auto' }}
      >
        {component}
      </div>
      {!!cursorImage && (
        <img
          sx={{ position: 'fixed', zIndex: 1000, width: ml('168px', '264px') }}
          style={{
            pointerEvents: 'none',
            cursor: 'none',
            display: isHovered ? 'block' : 'none',
            top: mouseY - y || 0,
            left: mouseX - x || 0,
          }}
          src={cursorImage?.src}
          alt=""
        />
      )}
    </div>
  )
}

const ComponentWithGifCursor: FC<ComponentWithGifCursorProps> = ({
  component,
  cursorImage,
}) => {
  if (isMobile) return component

  return (
    <ComponentWithGifCursorDesktop
      component={component}
      cursorImage={cursorImage}
    />
  )
}

export default ComponentWithGifCursor
