/** @jsxImportSource theme-ui */
import { FC, useState, useRef } from 'react'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { PrismicImageType } from 'graphql-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getPrismicImage } from 'src/utils/gatsby'
import { H1, H2, BodyLarge } from 'src/components/atoms/text'
import { xs } from 'src/gatsby-plugin-theme-ui/utils'

type Props = {
  title: string
  images: { image: PrismicImageType }[]
  lang: string
}
const getDayOfYear = () => {
  const now: any = new Date()
  const start: any = new Date(now.getFullYear(), 0, 0)
  const diff = now - start
  const oneDay = 1000 * 60 * 60 * 24
  return Math.floor(diff / oneDay)
}

/**
 *
 * @param height Height of the image
 * @returns the percentage (e.g. 0.7) of the height, so it scales proportionally
 */
const calculateImagePercentage = (height: number) => 376 / height

const TodayMood: FC<Props> = ({ title, images, lang }) => {
  const [activeImage] = useState(getDayOfYear() % images.length)
  const imageRef = useRef<HTMLDivElement>(null)

  const todayDate = new Date()

  const imagePercetange = calculateImagePercentage(
    images[activeImage]?.image?.dimensions?.height as number
  )
  const imageDesktop = images[activeImage]?.image
  const height =
    (images[activeImage]?.image?.dimensions?.height as number) * imagePercetange
  const width =
    (images[activeImage]?.image?.dimensions?.width as number) * imagePercetange

  return (
    <div sx={{ textAlign: 'center', mb: xs('xl', 'xxl') }}>
      <H1
        sx={{ mb: xs('m', 'l2') }}
      >{`${todayDate.getDate()} ${todayDate.toLocaleString(lang, {
        month: 'long',
      })} ${todayDate.getFullYear()}`}</H1>
      <H2 as="div" sx={{ mb: xs('l', 'xl') }}>
        {replaceHtml(title || '', 'p', BodyLarge)}
      </H2>
      <div>
        <div
          ref={imageRef}
          sx={{
            mb: 'm2',
            margin: 'auto',
            height: xs('100%', height),
          }}
        >
          <GatsbyImage
            image={getPrismicImage(
              imageDesktop,
              'fullWidth',
              imageDesktop?.alt || ''
            )}
            alt={imageDesktop?.alt || ''}
            objectFit="fill"
            sx={{
              height: xs('100%', height),
              width: xs('100%', width),
              margin: 'auto',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TodayMood
