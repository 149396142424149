/** @jsxImportSource theme-ui */
import { FC, useState, useEffect } from 'react'
import { ThinLine } from 'src/components/atoms/layout'
import { PrismicAboutpageDataType, PrismicImageType } from 'root/graphql-types'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import {
  VideoWithCursor,
  ServicesList,
  IndustriesEditorialText,
  ClientsSlider,
} from 'src/components/organisms'
import { motion } from 'framer-motion'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import FullViewportHeadline from 'src/components/molecules/FullViewportHeadline'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'
import { EditorialValues, TodayMood } from 'src/components/pages/about'

type ClientList = { name: string; industries: string[] }[]

type AboutPageProps = {
  pageContext: PrismicAboutpageDataType & {
    clients_list: ClientList
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
    intro_text: any
    middle_heading?: any
    team_list: {
      full_name?: string
      position?: string
      cursor_image?: { src: string; x: number; y: number }
    }[]
    aboutVideoDesktop: any
    aboutVideoMobile: any
    industries_text: any
    industries_left_images: any
    industries_right_images: any
    values_title: any
    values: any
    mood_title: any
    mood_images: any
    clients: any
    clients_heading: string
  }
  path: string
}

const AboutPage: FC<AboutPageProps> = ({ pageContext, ...props }) => {
  const {
    intro_text,
    aboutVideoMobile,
    aboutVideoDesktop,
    middle_heading,
    industries_text,
    industries_left_images,
    industries_right_images,
    services_title,
    services,
    services_see_more_label,
    seo_title,
    seo_image_1200x1200,
    seo_description,
    values_title,
    values,
    mood_title,
    mood_images,
    clients,
    clients_heading,
  } = pageContext

  setActiveLink('about')

  const [forceRenderKey, setForceRenderKey] = useState('')

  useEffect(() => {
    setForceRenderKey('rerender')
  }, [])
  return (
    <div key={forceRenderKey}>
      <SEO
        title={seo_title}
        image={seo_image_1200x1200?.fluid?.src}
        description={seo_description}
        path={props.path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <AnimationScrollYFix />
      <FullViewportHeadline headline={intro_text} />
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 2 })}
      >
        <VideoWithCursor
          key={forceRenderKey}
          videoDesktop={aboutVideoDesktop}
          videoMobile={aboutVideoMobile}
          muted={true}
          poster
          showSoundToggle
        />
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 3 })}
      >
        <IndustriesEditorialText
          text={industries_text.html}
          leftImages={industries_left_images}
          rightImages={industries_right_images}
        />
        <FullViewportHeadline headline={middle_heading} headlineAs="h2" />
        <ThinLine sx={{ mb: ml('m', 'xxl') }} />
        <ServicesList
          title={services_title}
          services={services}
          seeMoreLabel={services_see_more_label || ''}
        />
        <ThinLine sx={{ mt: ml('m', 104 - 56), mb: ml('l', 'xxl') }} />
        <ClientsSlider heading={clients_heading} clients={clients} />
        <ThinLine sx={{ mb: ml('l', 'xxl'), mt: ml('l', 'xxl') }} />
        <EditorialValues title={values_title} values={values} />
        <ThinLine sx={{ mt: ml('l', 'xxl'), mb: ml('l', 'xxl') }} />
        <TodayMood
          title={mood_title}
          images={mood_images}
          lang={pageContext.lang}
        />
      </motion.div>
    </div>
  )
}

export default AboutPage
